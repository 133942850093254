import React from "react";
import "./footer.css";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__container container">
                <h1 className="footer__title">Haavish</h1>

                <ul className="footer__list">
                    <li>
                        <a href="#about" className="footer__link">
                            About
                        </a>
                    </li>

                    <li>
                        <a href="#portfolio" className="footer__link">
                            Projects
                        </a>
                    </li>

                    <li>
                        <a href="#journey" className="footer__link">
                            Journey
                        </a>
                    </li>
                </ul>

                <div className="footer__social">
                    <a
                        href="https://www.youtube.com/haavishbonala"
                        className="footer__social-link"
                        target="_blank"
                    >
                        <i class="bx bxl-youtube"></i>
                    </a>
                    <a
                        href="https://github.com/HaavishBonala"
                        className="footer__social-link"
                        target="_blank"
                    >
                        <i class="bx bxl-github"></i>
                    </a>
                    <a
                        href="https://twitter.com/haavishb"
                        className="footer__social-link"
                        target="_blank"
                    >
                        <i class="bx bxl-twitter"></i>
                    </a>
                </div>

                <span className="footer__copy">
                    &#169; HaavishBonala. All rights reserved
                </span>
            </div>
        </footer>
    );
};

export default Footer;
