import Work1 from "../../assets/work1.png";

export const projectsData = [
  {
    id: 1,
    image: Work1,
    title: "Food App",
    category: "web",
  },
];

export const projectsNav = [
  {
    name: "all",
  },
  {
    name: "web",
  },
  {
    name: "app",
  },
  {
    name: "embedded",
  },
];
