import React, { useState } from "react";
import "./journey.css";
const Journey = () => {
    const [toggleState, setToggleState] = useState(1);

    const toggleTabs = (index) => {
        setToggleState(index);
    };

    return (
        <section className="journey section" id="journey">
            <h2 className="section__title">Journey</h2>
            <span className="section__subtitle">My Journey</span>

            <div className="journey__container container">
                <div className="journey__tabs">
                    <div
                        className={
                            toggleState === 1
                                ? "journey__button journey__active button--flex"
                                : "journey__button button--flex"
                        }
                        onClick={() => toggleTabs(1)}
                    >
                        <i className="uil uil-graduation-cap journey__icon"></i>{" "}
                        Education
                    </div>

                    <div
                        className={
                            toggleState === 2
                                ? "journey__button journey__active button--flex"
                                : "journey__button button--flex"
                        }
                        onClick={() => toggleTabs(2)}
                    >
                        <i className="uil uil-briefcase-alt journey__icon"></i>{" "}
                        Personal
                    </div>
                </div>

                <div className="journey__sections">
                    <div
                        className={
                            toggleState === 1
                                ? "journey__content journey__content-active"
                                : "journey__content"
                        }
                    >
                        <div className="journey__data">
                            <div>
                                <h3 className="journey__title">
                                    Secondary School
                                </h3>
                                <span className="journey__subtitle">
                                    Year 7 - 9 @ The King's School Grantham
                                </span>
                                <div className="journey__calendar">
                                    <i className="uil uil-calendar-alt"></i>{" "}
                                    2021 - Present
                                </div>
                            </div>

                            <div>
                                <span className="journey__rounder"></span>
                                <span className="journey__line"></span>
                            </div>
                        </div>
                        <div className="journey__data">
                            <div></div>

                            <div>
                                <span className="journey__rounder"></span>
                                <span className="journey__line"></span>
                            </div>

                            <div>
                                <h3 className="journey__title">
                                    Primary School
                                </h3>
                                <span className="journey__subtitle">
                                    Year 1 - 6 @ Greenfields Community School
                                </span>
                                <div className="journey__calendar">
                                    <i className="uil uil-calendar-alt"></i>{" "}
                                    2015 - 2021
                                </div>
                            </div>
                        </div>
                        <div className="journey__data">
                            <div>
                                <h3 className="journey__title">
                                    Kindergarden and Reception
                                </h3>
                                <span className="journey__subtitle">
                                    @ Matrusri DAV Public School
                                </span>
                                <div className="journey__calendar">
                                    <i className="uil uil-calendar-alt"></i>{" "}
                                    2012 - 2015
                                </div>
                            </div>

                            <div>
                                <span className="journey__rounder"></span>
                                <span className="journey__line"></span>
                            </div>
                        </div>
                    </div>

                    <div
                        className={
                            toggleState === 2
                                ? "journey__content journey__content-active"
                                : "journey__content"
                        }
                    >
                        <div className="journey__data">
                            <div>
                                <h3 className="journey__title">React JS</h3>
                                <span className="journey__subtitle">
                                    Learning in progress and this is the first
                                    big React JS project for me
                                </span>
                                <div className="journey__calendar">
                                    <i className="uil uil-calendar-alt"></i>{" "}
                                    2022 - Present
                                </div>
                            </div>

                            <div>
                                <span className="journey__rounder"></span>
                                <span className="journey__line"></span>
                            </div>
                        </div>
                        <div className="journey__data">
                            <div></div>

                            <div>
                                <span className="journey__rounder"></span>
                                <span className="journey__line"></span>
                            </div>

                            <div>
                                <h3 className="journey__title">Python</h3>
                                <span className="journey__subtitle">
                                    Advanced Python
                                </span>
                                <div className="journey__calendar">
                                    <i className="uil uil-calendar-alt"></i>{" "}
                                    2021 - 2022
                                </div>
                            </div>
                        </div>
                        <div className="journey__data">
                            <div>
                                <h3 className="journey__title">Java</h3>
                                <span className="journey__subtitle">
                                    Core and Advanced Java , Selenium Testing
                                    and API Testing
                                </span>
                                <div className="journey__calendar">
                                    <i className="uil uil-calendar-alt"></i>{" "}
                                    2019 - 2021
                                </div>
                            </div>

                            <div>
                                <span className="journey__rounder"></span>
                                <span className="journey__line"></span>
                            </div>
                        </div>
                        <div className="journey__data">
                            <div></div>

                            <div>
                                <span className="journey__rounder"></span>
                                <span className="journey__line"></span>
                            </div>

                            <div>
                                <h3 className="journey__title">
                                    Web Development
                                </h3>
                                <span className="journey__subtitle">
                                    HTML , CSS and Basic JS
                                </span>
                                <div className="journey__calendar">
                                    <i className="uil uil-calendar-alt"></i>{" "}
                                    2018 - 2019
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Journey;
