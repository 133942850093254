import React from "react";

const Info = () => {
    return (
        <div className="about__info grid">
            <div className="about__box">
                <i className="mdi mdi-chess-king about__icon"></i>
                <h3 className="about__title">Game</h3>
                <span className="about__subtitle">Chess</span>
            </div>

            <div className="about__box">
                <i className="mdi mdi-table-tennis about__icon"></i>
                <h3 className="about__title">Sport</h3>
                <span className="about__subtitle">Table Tennis</span>
            </div>

            <div className="about__box">
                <i className="mdi mdi-raspberry-pi about__icon"></i>
                <h3 className="about__title">Hobbies</h3>
                <span className="about__subtitle">Arduino / RPi</span>
            </div>
        </div>
    );
};

export default Info;
