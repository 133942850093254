import React from "react";

const Social = () => {
    return (
        <div className="home__social">
            <a
                href="https://www.youtube.com/haavishbonala"
                className="home__social-icon"
                target="_blank"
            >
                <i className="uil uil-youtube"></i>
            </a>
            <a
                href="https://github.com/HaavishBonala"
                className="home__social-icon"
                target="_blank"
            >
                <i className="uil uil-github"></i>
            </a>
            <a
                href="https://twitter.com/haavishb"
                className="home__social-icon"
                target="_blank"
            >
                <i class="uil uil-twitter"></i>
            </a>
        </div>
    );
};

export default Social;
