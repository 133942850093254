import React from "react";
import "./about.css";
import AboutImg from "../../assets/about.jpg";
import Info from "./Info";

const About = () => {
    return (
        <section className="about section" id="about">
            <h2 className="section__title">About Me</h2>
            <span className="section__subtitle">My Introduction</span>

            <div className="about__container container grid">
                <img src={AboutImg} alt="" className="about__img" />

                <div className="about__data">
                    <Info />

                    <p className="about__description">
                        Hello, I'm Haavish Bonala, a 9th grader at The King's
                        Grammar School. I bring my ideas to life through
                        programming, create content on YouTube, and have a keen
                        interest in math, science, and computer science at
                        school.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default About;
